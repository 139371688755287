<template>
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
        :sort="'name'"
        :modalSize="'modal-xl'"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :selectFields=selectFields
        ref="overview"
    >
        <template #extrasearchoptions>
            <div style="width: 25rem">
                <FormItem type="select" v-model="typeSelect" :options="relationTypes" :label="$t('overview.type')" @onchange="filterOnType" :placeholder="$t('form.all')" />
            </div>
        </template>
        <template #columns>
            <Column field="name" :header="$t('overview.name')"> </Column>
            <Column field="relation_number" :header="$t('crm.registration_id')"> </Column>
            <Column field="vat" :header="$t('accounting.vat_id')"> </Column>
            <Column field="country.name" :header="$t('overview.country')"> </Column>
        </template>
        <template #form>
            <RelationFormComponent ref="ItemForm" :itemData="item" />
        </template>
        <template #modal-footer>
            <button id="nextbutton" type="button" class="btn btn-success" @click.prevent="next">&gt;&ensp;{{$t('form.continue_to_step')}}<span id="nextStep">2</span></button>
        </template>
    </Overview>

</template>

<script>
import relationService from '@/services/RelationService';
import Overview from '@/components/Overview';
import Column from '@/components/column';
import RelationFormComponent from '@/components/relation/RelationFormComponent';

export default {
    components: {
        Overview,
        Column,
        RelationFormComponent
    },
    data() {
        return {
            item: {},
            itemService: relationService,
            itemFormRef: null,
            relationTypes: [
                { id: 'carrier', name: this.$t('navigation.carriers') },
                { id: 'customer', name: this.$t('navigation.customers') },
                { id: 'manufacturer', name: this.$t('crm.manufacturer') },
                { id: 'representative', name: this.$t('overview.representative') },
                { id: 'office', name: this.$t('crm.company') },
                { id: 'supplier', name: this.$t('navigation.vendors') },
                { id: 'surveyor', name: this.$t('overview.surveyer') },
                { id: 'terminal', name: this.$t('navigation.terminals') },
                { id: 'vesselOwner', name: this.$t('navigation.vessel_owners') },
            ],
            typeSelect: null,
            selectFields: [
                "id",
                "name",
                "relation_number",
                "vat",
                "country_id",{
                    "country": [
                        "id",
                        "name"
                    ]
                },
                /*"is_manufacturer",
                'has_terminal',
                'has_carrier',
                'has_vessel_owner',
                'has_customer',
                'has_office',
                'has_surveyor',
                'has_supplier',
                'is_contact',
                'has_surveyor',
                'is_port_representative',
                {
                    "worldcheckCase": [
                        "id",
                        "status",
                        "relation_id"
                    ]
                }*/
            ]
        }
    },
    methods: {
        next() {
            let tabs = document.querySelectorAll("#formModal ul.nav-tabs .nav-link");
            let next = false;
            for(let tab of tabs) {
                if(next) {
                    tab.click();
                    break;
                }
                if(tab.className == "nav-link active") next = true;
                
            }
        },
        getRelationTypes(relation){
            let relations = [];
            if(relation.has_customer) relations.push(this.$tc('navigation.customers', 1));
            if(relation.has_terminal) relations.push(this.$t('overview.terminal'));
            if(relation.has_vessel_owner) relations.push(this.$tc('navigation.vessel_owners', 1));
            if(relation.has_carrier) relations.push(this.$tc('navigation.carriers', 1));
            if(relation.is_port_representative) relations.push(this.$t('overview.representative', 1));
            if(relation.is_manufacturer) relations.push(this.$t('crm.manufacturer', 1));
            if(relation.has_office) relations.push(this.$t('crm.company'));
            if(relation.has_surveyor) relations.push(this.$tc('overview.surveyer', 1));
            if(relation.has_supplier) relations.push(this.$t('navigation.vendors'));
            relations.sort();
            return relations.join(', ');
        },
        filterOnType(e){
            this.$refs.overview.setExtraParams(e.target.value == this.$t('form.all') ? false : e.target.value);
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;

        this.relationTypes.sort((a, b) => a.name > b.name ? 1 : -1);
    }
}
</script>